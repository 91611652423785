var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"modal fade",attrs:{"id":"modal-historial-estado"}},[_c('div',{staticClass:"modal-dialog modal-lg"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header bg-frontera-top-left pt-2 pb-2"},[_c('h4',{staticClass:"modal-title text-white"},[_vm._v(" Historial de cambio "+_vm._s(_vm.form_historial.titulo)+" ")]),_c('button',{ref:"closeModal",staticClass:"close text-white",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close","id":"close-modal"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),(_vm.entidades !== null && _vm.entidades != undefined)?_c('div',{staticClass:"modal-body"},[_c('div',[_c('table',{staticClass:"table table-bordered table-striped table-hover table-sm"},[_vm._m(0),_c('tbody',_vm._l((_vm.entidades.data),function(entidad){return _c('tr',{key:entidad.id},[_c('td',{attrs:{"align":"center"}},[_c('span',{staticClass:"badge",class:[
                        entidad.valor_ant == 1
                          ? 'badge-success'
                          : 'badge-danger',
                      ]},[_vm._v(" "+_vm._s(entidad.nEstadoAnterior)+" ")])]),_c('td',{attrs:{"align":"center"}},[_c('span',{staticClass:"badge",class:[
                        entidad.valor_nuevo == 1
                          ? 'badge-success'
                          : 'badge-danger',
                      ]},[_vm._v(" "+_vm._s(entidad.nEstadoNuevo)+" ")])]),_c('td',[_vm._v(_vm._s(entidad.justificacion))]),_c('td',[_vm._v(_vm._s(entidad.user.name))]),_c('td',[_vm._v(" "+_vm._s(_vm.moment(entidad.created_at).format("YYYY-MM-DD HH:mm:ss"))+" ")])])}),0)])]),_c('div',{staticClass:"card-footer"},[(_vm.entidades.total)?_c('div',{staticClass:"float-left"},[_c('p',[_vm._v(" Mostrando del "),_c('b',[_vm._v(_vm._s(_vm.entidades.from))]),_vm._v(" al "),_c('b',[_vm._v(_vm._s(_vm.entidades.to))]),_vm._v(" de un total: "),_c('b',[_vm._v(_vm._s(_vm.entidades.total))]),_vm._v(" Registros ")])]):_c('div',{staticClass:"float-left"},[_vm._m(1)]),_c('pagination',{attrs:{"data":_vm.entidades,"limit":5,"align":"right"},on:{"pagination-change-page":_vm.buscarEntidad}})],1)]):_vm._e()])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{attrs:{"align":"center"}},[_vm._v("Valor anterior")]),_c('th',{attrs:{"align":"center"}},[_vm._v("Valor nuevo")]),_c('th',[_vm._v("Justificación")]),_c('th',[_vm._v("Usuario")]),_c('th',[_vm._v("Fecha")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticClass:"badge badge-danger"},[_vm._v(" No hay registros para mostrar ")])])
}]

export { render, staticRenderFns }